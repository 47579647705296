<template>
    <div>
        <div style="text-align: right">
            <el-button
                size="small"
                type="primary"
                @click="onShowInclinometer('add')"
                >添加</el-button
            >
        </div>

        <el-table
            class="list-table"
            :data="inclinometerData"
            style="width: 100%; margin-top: 20px"
            :stripe="true"
            :border="false"
        >
            <el-table-column label="编号" prop="Id"></el-table-column>
            <el-table-column label="传感器" prop="SensorId"></el-table-column>
            <el-table-column label="深度" prop="Depth"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                        type="success"
                        icon="el-icon-edit"
                        size="small"
                        @click="
                            onShowInclinometer('edit', scope.row, scope.$index)
                        "
                        >修改</el-button
                    >
                    <el-button
                        type="warning"
                        icon="el-icon-error"
                        size="small"
                        style="margin-left: 10px"
                        @click="onInclinometerRemove(scope.row, scope.index)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            :title="
                formInclinometerType == 'add' ? '添加测斜深度' : '修改测斜深度'
            "
            :visible.sync="dialogFormInclinometerVisible"
            width="550px"
            custom-class="geology-dialog"
            :modal-append-to-body="true"
            :destroy-on-close="true"
        >
            <el-form
                ref="refFormInclinometer"
                class="geology-form"
                :rules="formRules"
                :model="formInclinometer"
                label-width="120px"
                style="padding-top: 20px"
            >
                <el-row>
                    <el-col :span="20" :offset="1">
                        <el-form-item label="传感器" prop="SensorId">
                            <el-input
                                v-model="formInclinometer.SensorId"
                                size="small"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="深度" prop="Depth">
                            <el-input
                                v-model="formInclinometer.Depth"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="23" :offset="1">
                        <el-form-item>
                            <el-button
                                type="primary"
                                size="medium"
                                @click="onInclinometerSubmit"
                                >立即保存</el-button
                            >
                            <el-button
                                type="primary"
                                plain
                                size="medium"
                                style="margin-left: 10px"
                                @click="dialogFormInclinometerVisible = false"
                                >取消</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import {
    getDeviceCxDepth,
    addDeviceCxDepth,
    editDeviceCxDepth,
    delDeviceCxDepth,
} from "@/api/device";
export default {
    data() {
        return {
            //初始值
            inclinometerData: [],
            dialogFormInclinometerVisible: false,
            formInclinometerType: "add",
            formInclinometer: {
                DeviceId: 0,
                Depth: "",
                SensorId: "",
            },
            formInclinometerFieldSelect: [],
            formRules: {
                SensorId: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入传感器",
                    },
                ],
                Depth: [
                    { required: true, trigger: "blur", message: "请输入深度" },
                ],
            },
            editIndex: 0,
        };
    },
    props: {
        value: {
            type: Array,
        },
        detailid: {
            type: [String, Number],
            default: 0,
        },
    },
    methods: {
        onShowInclinometer(type, item, index) {
            if (type == "add") {
                this.formInclinometer = {
                    DeviceId: this.detailid,
                    Depth: "",
                    SensorId: "",
                };
            } else {
                this.formInclinometer = JSON.parse(JSON.stringify(item));
                this.editIndex = index;
            }
            this.formInclinometerType = type;
            this.dialogFormInclinometerVisible = true;
        },
        onInclinometerSubmit() {
            this.$refs["refFormInclinometer"].validate((valid) => {
                if (valid) {
                    if (this.formInclinometerType == "add") {
                        addDeviceCxDepth(this.formInclinometer)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.dialogFormInclinometerVisible = false;
                                    this.getListData();
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    } else {
                        editDeviceCxDepth(this.formInclinometer)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.dialogFormInclinometerVisible = false;
                                    this.getListData();
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    }
                }
            });
        },
        onInclinometerRemove(item, index) {
            this.$confirm("确定要删除选择的数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delDeviceCxDepth(item.Id)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("删除成功!");
                                this.getListData();
                            } else {
                                this.$message.error("删除失败!" + res.msg);
                            }
                        })
                        .catch((errmsg) => {
                            this.$message.error(errmsg);
                        });
                })
                .catch(() => {});
        },
        getListData() {
            getDeviceCxDepth(this.detailid).then((res) => {
                if (res.code == 0) {
                    this.inclinometerData = res.data;
                }
            });
        },
    },
    created() {
        if (this.detailid > 0) {
            this.getListData();
        }
    },
    watch: {
        detailid: function () {
            if (this.detailid > 0) {
                this.getListData();
            }
        },
    },
};
</script>