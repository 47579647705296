<template>
    <div>
        <div style="text-align: right">
            <el-button size="small" type="primary" @click="onShowWarning('add')"
                >添加</el-button
            >
        </div>

        <el-table
            class="list-table"
            :data="warningData"
            style="width: 100%; margin-top: 20px"
            :stripe="true"
            :border="false"
        >
            <el-table-column
                label="传感器编号"
                prop="SensorId"
            ></el-table-column>
            <el-table-column label="名称" prop="FieldName"></el-table-column>
            <el-table-column
                label="预警值"
                prop="WarningValue"
            ></el-table-column>
            <el-table-column
                label="最大预警值"
                prop="MaxWarningValue"
            ></el-table-column>
            <el-table-column label="预警等级" prop="WarningType">
                <template slot-scope="scope">
                    {{ formWarningTypeSelect[scope.row.WarningType - 1] }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                        type="success"
                        icon="el-icon-edit"
                        size="small"
                        @click="onShowWarning('edit', scope.row, scope.$index)"
                        >修改</el-button
                    >
                    <el-button
                        type="warning"
                        icon="el-icon-error"
                        size="small"
                        style="margin-left: 10px"
                        @click="onWarningRemove(scope.row, scope.index)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            :title="formWarningType == 'add' ? '添加预警' : '修改预警'"
            :visible.sync="dialogFormWarningVisible"
            width="750px"
            custom-class="geology-dialog"
            :modal-append-to-body="true"
            :destroy-on-close="true"
        >
            <el-form
                ref="refFormWarning"
                class="geology-form"
                :rules="formRules"
                :model="formWarning"
                label-width="120px"
                style="padding-top: 20px"
            >
                <el-row>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="传感器" prop="SensorId">
                            <el-input
                                v-model="formWarning.SensorId"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="对应字段" prop="FieldName">
                            <el-select
                                style="width: 100%"
                                v-model="formWarning.FieldName"
                                placeholder="请选择对应字段"
                                size="medium"
                            >
                                <el-option
                                    v-for="item in formWarningFieldSelect"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="预警值" prop="WarningValue">
                            <el-input
                                placeholder="请输入预警值"
                                v-model="formWarning.WarningValue"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="最大预警值" prop="MaxWarningValue">
                            <el-input
                                placeholder="请输入最大预警值"
                                v-model="formWarning.MaxWarningValue"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="10" :offset="1">
                        <el-form-item label="预警等级" prop="WarningType">
                            <el-select
                                style="width: 100%"
                                v-model="formWarning.WarningType"
                                placeholder="请选择预警等级"
                                size="medium"
                            >
                                <el-option
                                    v-for="(
                                        item, index
                                    ) in formWarningTypeSelect"
                                    :key="item"
                                    :label="item"
                                    :value="index + 1"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="预警时间" prop="TotleTime">
                            <el-autocomplete
                                style="width: 100px"
                                class="inline-input"
                                v-model="formWarning.TotleTime"
                                :fetch-suggestions="fetchSuggestions"
                                placeholder="请输入预警时间"
                            ></el-autocomplete
                            ><span style="margin-left: 10px">分钟</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="平均速率" prop="AvgRate">
                            <el-input
                                v-model="formWarning.AvgRate"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="23" :offset="1">
                        <el-form-item>
                            <el-button
                                type="primary"
                                size="medium"
                                @click="onWarningSubmit"
                                >立即保存</el-button
                            >
                            <el-button
                                type="primary"
                                plain
                                size="medium"
                                style="margin-left: 10px"
                                @click="dialogFormWarningVisible = false"
                                >取消</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import {
    getDeviceWarning,
    delDeviceWarning,
    addDeviceWarning,
    editDeviceWarning,
} from "@/api/device";
export default {
    data() {
        return {
            //初始值
            warningData: [],
            dialogFormWarningVisible: false,
            formWarningType: "add",
            formWarning: {
                DeviceId: 0,
                SensorId: 0,
                FieldName: "",
                Direction: "",
                WarningValue: "",
                TotleTime: "0",
                MaxWarningValue: "",
                Marks: "",
                ContrastType: 0,
                AvgRate: "0",
                Id: 0,
            },
            formRules: {
                SensorId: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入传感器",
                    },
                ],
                FieldName: [
                    { required: true, trigger: "blur", message: "请选择字段" },
                ],
                WarningValue: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入预警值",
                    },
                ],
                MaxWarningValue: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入最大预警值",
                    },
                ],
                WarningType: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请选择预警等级",
                    },
                ],
                TotleTime: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入预警时间",
                    },
                ],
            },
            formWarningFieldSelect: [
                "v1",
                "v2",
                "v3",
                "v4",
                "v5",
                "v6",
                "v7",
                "v8",
            ],
            formWarningTypeSelect: ["蓝色", "黄色", "橙色", "红色"],
            warningTypeData: [],
            editIndex: 0,
        };
    },
    props: {
        value: {
            type: Array,
        },
        detailid: {
            type: [String, Number],
            default: 0,
        },
        formtype: {
            type: String,
            defalut: "add",
        },
    },
    methods: {
        fetchSuggestions(queryString, cb) {
            cb([
                {
                    value: 10,
                },
                {
                    value: 30,
                },
                {
                    value: 60,
                },
            ]);
        },
        onShowWarning(type, item, index) {
            if (type == "add") {
                this.formWarning = {
                    DeviceId: this.detailid,
                    SensorId: 0,
                    FieldName: "",
                    Direction: "",
                    WarningValue: "",
                    TotleTime: "0",
                    WarningType: "",
                    Marks: "",
                    ContrastType: 0,
                    AvgRate: "0",
                    Id: 0,
                };
            } else {
                item.TotleTime += "";
                this.formWarning = JSON.parse(JSON.stringify(item));
                this.editIndex = index;
            }
            this.formWarningType = type;
            this.dialogFormWarningVisible = true;
        },
        onWarningSubmit() {
            this.$refs["refFormWarning"].validate((valid) => {
                if(!valid){
                    return;
                }
                if (this.formWarningType == "add") {
                    if (this.formtype == "add") {
                        this.warningData.push(
                            JSON.parse(JSON.stringify(this.formWarning))
                        );
                        this.$emit("input", this.warningData);
                    } else {
                        addDeviceWarning(this.formWarning)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.getListData();
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    }
                } else {
                    if (this.formtype == "add") {
                        var valueData = this.warningData;
                        valueData[this.editIndex] = JSON.parse(
                            JSON.stringify(this.formWarning)
                        );
                        this.warningData = [];
                        setTimeout(() => {
                            this.warningData = valueData;
                            this.$emit("input", valueData);
                        }, 10);
                    } else {
                        editDeviceWarning(this.formWarning)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.getListData();
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    }
                }
                this.dialogFormWarningVisible = false;
            })
            
        },
        onWarningRemove(item, index) {
            if (this.formtype == "edit") {
                delDeviceWarning(item.Id)
                    .then((res) => {
                        if (res.code == 0) {
                            this.$message.success("删除成功!");
                            this.warningData.splice(index, 1);
                        } else {
                            this.$message.error("删除失败!" + res.msg);
                        }
                    })
                    .catch((errmsg) => {
                        this.$message.error(errmsg);
                    });
            } else {
                this.warningData.splice(index, 1);
            }
        },
        getListData() {
            getDeviceWarning(1, 100, {
                deviceid: this.detailid,
            }).then((res) => {
                if (res.code == 0) {
                    this.warningData = res.data.data;
                    this.$emit("input", this.warningData);
                }
            });
        },
    },
    created() {
        this.$store.dispatch("dictionary/getWarningType").then((data) => {
            this.warningTypeData = data;
        });
    },
    watch: {
        detailid: function () {
            if (this.detailid > 0) {
                this.getListData();
            }
        },
    },
};
</script>