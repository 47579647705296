<template>
    <div class="warning-push-index">
        <div class="container-header">
            <div class="top-actions-group">
                <div class="action-left">
                    <el-button
                        type="primary"
                        icon="el-icon-circle-plus-outline"
                        size="medium"
                        @click="onAdd"
                        v-if="userinfo.isAdmin"
                        >新增</el-button
                    >
                </div>
                <div class="action-right"></div>
            </div>
        </div>

        <el-table
            class="list-table"
            ref="multipleTable"
            :data="listData"
            tooltip-effect="dark"
            style="width: 100%"
            :stripe="true"
            :border="false"
            v-loading="tableLoading"
        >
            <!-- <el-table-column
                label="监测点"
                prop="DangerName"
                v-if="listfilter.type == 0"
            ></el-table-column>
            <el-table-column
                label="监测点位"
                prop="MonitorName"
                v-if="listfilter.type == 1"
            ></el-table-column>
            <el-table-column
                label="设备名称"
                prop="DeviceName"
                v-if="listfilter.type == 1"
            ></el-table-column>
            <el-table-column
                label="设备编号"
                prop="DeviceCode"
                v-if="listfilter.type == 1"
            ></el-table-column> -->
            <el-table-column label="姓名" prop="Name"></el-table-column>
            <el-table-column label="手机号码" prop="Phone"></el-table-column>
            <el-table-column label="创建时间" prop="CreateTime">
                <template slot-scope="scope">
                    {{ scope.row.CreateTime | datetime }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="160">
                <template slot-scope="scope">
                    <el-button
                        type="success"
                        icon="el-icon-edit"
                        size="mini"
                        @click="onEdit(scope.row)"
                        >修改</el-button
                    >
                    <el-button
                        type="warning"
                        icon="el-icon-error"
                        size="mini"
                        style="margin-left: 10px"
                        @click="onListDel(scope.row)"
                        >删除</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            class="list-pagination"
            :page-size="page.pagesize"
            hide-on-single-page
            :total="page.total"
            :current-page.sync="page.pageindex"
            @current-change="onPageChange"
        >
        </el-pagination>

        <el-dialog
            :title="formtype == 'add' ? '添加接收人' : '修改接收人'"
            :visible.sync="dialogFormVisible"
            width="550px"
            custom-class="geology-dialog"
            :modal-append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            @before-close="onFormClose"
        >
            <el-form
                ref="form"
                class="geology-form"
                :rules="formRules"
                :model="form"
                label-width="120px"
                style="padding-top: 20px"
            >
                <el-row>
                    <el-col :span="21" :offset="1">
                        <el-form-item label="人员姓名" prop="Name">
                            <el-input
                                v-model="form.Name"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="21" :offset="1">
                        <el-form-item label="手机号码" prop="Phone">
                            <el-input
                                v-model="form.Phone"
                                size="small"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="23" :offset="1">
                        <el-form-item>
                            <el-button
                                type="primary"
                                size="medium"
                                @click="onSubmit"
                                >立即保存</el-button
                            >
                            <el-button
                                type="primary"
                                plain
                                size="medium"
                                style="margin-left: 10px"
                                @click="onFormClose"
                                >取消</el-button
                            >
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
    getDeviceWarningAcceptList,
    addWarningAccept,
    editWarningAccept,
    delWarningAccept,
} from "@/api/device";
export default {
    data() {
        return {
            listData: [],
            tableLoading: false,

            filterDaterange: "",
            getDangerLoading: false,
            dangerSelect: [],
            getMonitorLoading: false,
            monitorSelect: [],

            listfilter: {
                type: 1,
                dangerid: "",
                monitorid: "",
                fstarttime: "",
                fendtime: "",
            },
            page: {
                pagesize: 10,
                pageindex: 1,
                total: 0,
            },

            dialogFormVisible: false,
            roleSelect: [],
            formtype: "add",
            formImageList: [],
            form: {
                DangerId: "",
                MonitorId: "",
                DeviceName: "",
                DeviceCode: "",
                Name: "",
                Phone: "",
                HeadIcon: "",
                UserId: "",
                Id: 0,
            },
            formHeadIcon: [],
            formRules: {
                Name: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入人员姓名",
                    },
                ],
                Phone: [
                    {
                        required: true,
                        trigger: "blur",
                        message: "请输入手机号码",
                    },
                ],
            },

            dialogDetailVisible: false,
            activeInspectDetail: "",
        };
    },
    props: {
        deviceInfo: {
            type: [Object,String],
        },
    },
    computed: {
        ...mapGetters(["userinfo"]),
    },
    components: {
    },
    methods: {
        onSearch() {
            this.page.pageindex = 1;
            this.getListData();
        },
        onPageChange(pageindex) {
            this.page.pageindex = pageindex;
            this.getListData();
        },
        getListData() {
            this.tableLoading = true;
            getDeviceWarningAcceptList(
                this.page.pageindex,
                this.page.pagesize,
                this.listfilter
            ).then((resdata) => {
                this.tableLoading = false;
                if (resdata.code == 0) {
                    this.listData = resdata.data.data;
                    this.page.total = resdata.data.count * 1;
                } else {
                    this.$message({
                        type: "error",
                        message: resdata.msg,
                    });
                }
            });
        },
        onListDel(item) {
            this.$confirm("确定要删除选择的数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delWarningAccept(item.Id)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("删除成功!");
                                this.getListData();
                            } else {
                                this.$message.error("删除失败!" + res.msg);
                            }
                        })
                        .catch((errmsg) => {
                            this.$message.error(errmsg);
                        });
                })
                .catch(() => {});
        },
        onAdd() {
            this.form = {
                DangerId: "",
                MonitorId: this.deviceInfo.Monitorid,
                Name: "",
                Phone: "",
                HeadIcon: "",
                UserId: "",
                DeviceName: this.deviceInfo.DeviceName,
                DeviceCode: this.deviceInfo.DeviceCode,
                Id: 0,
            };
            this.formtype = "add";
            this.formHeadIcon = [];
            this.dialogFormVisible = true;
        },
        onEdit(item) {
            //   item.DeviceName="";
            //   item.DeviceCode="";
            console.log(JSON.stringify(item));
            this.form = JSON.parse(JSON.stringify(item));
            if (this.form.DangerId == 0) {
                this.form.DangerId = "";
                this.monitorSelect.push({
                    Id: this.form.MonitorId,
                    MonitorName: this.form.MonitorName,
                });
            } else {
                this.form.MonitorId = "";
                this.getMonitorList();
            }
            this.formtype = "edit";
            this.formHeadIcon = this.form.HeadIcon
                ? [
                      {
                          url: this.form.HeadIcon,
                      },
                  ]
                : [];
            //this.onMonitorChange(this.form.MonitorId);
            this.dialogFormVisible = true;
        },
        onFormClose() {
            this.getListData();
            this.formHeadIcon = [];
            this.dialogFormVisible = false;
        },
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.formtype != "edit") {
                        addWarningAccept(this.form)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.onFormClose();
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    } else {
                        editWarningAccept(this.form)
                            .then((res) => {
                                if (res.code == 0) {
                                    this.$message.success("保存成功!");
                                    this.onFormClose();
                                } else {
                                    this.$message.error("保存失败!" + res.msg);
                                }
                            })
                            .catch((errmsg) => {
                                this.$message.error("保存失败!" + errmsg);
                            });
                    }
                } else {
                    return false;
                }
            });
        },
    },
    created() {
        if (this.deviceInfo) {
            this.listfilter.monitorid = this.deviceInfo.Monitorid;
            this.listfilter.dangerid = this.deviceInfo.DangerId;
            this.getListData();
        }
    },
    watch: {
        deviceInfo: function () {
            if (this.deviceInfo) {
                this.listfilter.monitorid = this.deviceInfo.Monitorid;
                this.listfilter.dangerid = this.deviceInfo.DangerId;
                this.getListData();
            }
        },
    },
};
</script>