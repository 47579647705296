<template>
  <div>
    <div style="text-align: right">
      <el-button size="small" type="primary" @click="onShowInitvalue('add')">添加</el-button>
    </div>

    <el-table class="list-table" :data="initValueData" style="width: 100%; margin-top: 20px" :stripe="true"
      :border="false">
      <el-table-column label="传感器编号" prop="SensorId"></el-table-column>
      <el-table-column label="名称" prop="Name"></el-table-column>
      <el-table-column label="显示名称" prop="ShowName"></el-table-column>
      <el-table-column label="对应数值" prop="FieldName"></el-table-column>
      <el-table-column label="初始值" prop="InitValue"></el-table-column>
      <el-table-column label="单位" prop="FieldUnit"></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="success" icon="el-icon-edit" size="small"
            @click="onShowInitvalue('edit', scope.row, scope.$index)">修改</el-button>
          <el-button type="warning" icon="el-icon-error" size="small" style="margin-left: 10px"
            @click="onInitvalueRemove(scope.row, scope.index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="formInitvalueType == 'add' ? '添加初始值' : '修改初始值'" :visible.sync="dialogFormInitvalueVisible"
      width="550px" custom-class="geology-dialog" :modal-append-to-body="true" :destroy-on-close="true">
      <el-form ref="refFormInitvalue" class="geology-form" :rules="formRules" :model="formInitvalue" label-width="120px"
        style="padding-top: 20px">
        <el-row>
          <el-col :span="20" :offset="1">
            <el-form-item label="显示名称" prop="ShowName">
              <el-input v-model="formInitvalue.ShowName" size="small"></el-input>
            </el-form-item>
            <el-form-item label="传感器" prop="SensorId">
              <el-input v-model="formInitvalue.SensorId" size="small"></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="Name">
              <el-input v-model="formInitvalue.Name" size="small"></el-input>
            </el-form-item>
            <el-form-item label="对应字段" prop="FieldName">
              <el-select style="width: 100%" v-model="formInitvalue.FieldName" placeholder="请选择对应字段" size="medium">
                <el-option v-for="item in formInitvalueFieldSelect" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="初始值" prop="InitValue">
              <el-input v-model="formInitvalue.InitValue" size="small"></el-input>
            </el-form-item>
            <el-form-item label="单位" prop="FieldUnit">
              <el-input v-model="formInitvalue.FieldUnit" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onInitvalueSubmit">立即保存</el-button>
              <el-button type="primary" plain size="medium" style="margin-left: 10px"
                @click="dialogFormInitvalueVisible = false">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDeviceInitvalue,
  delDeviceInitvalue,
  addDeviceInitvalue,
  editDeviceInitvalue,
} from "@/api/device";
export default {
  data() {
    return {
      //初始值
      initValueData: [],
      dialogFormInitvalueVisible: false,
      formInitvalueType: "add",
      formInitvalue: {
        ShowName: '',
        DeviceId: 0,
        Name: "",
        SensorId: "0",
        FieldName: "",
        InitValue: "",
        FieldUnit: "",
      },
      formInitvalueFieldSelect: [],
      formRules: {},
      editIndex: 0,
    };
  },
  props: {
    value: {
      type: Array,
    },
    detailid: {
      type: [String, Number],
      default: 0,
    },
    formtype: {
      type: String,
      defalut: "add",
    },
  },
  methods: {
    onShowInitvalue(type, item, index) {
      const initvalue = ["v1", "v2", "v3", "v4", "v5", "v6", "v7", "v8"];
      //   const noBindInitvalue = [];
      //   initvalue.forEach((value) => {
      //     var isexit = false;
      //     this.initValueData.forEach((elem) => {
      //       if (elem.FieldName == value) {
      //         isexit = true;
      //       }
      //     });
      //     if (!isexit) {
      //       noBindInitvalue.push(value);
      //     }
      //   });
      this.formInitvalueFieldSelect = initvalue;
      if (type == "add") {
        this.formInitvalue = {
          ShowName: '',
          DeviceId: this.detailid,
          Name: "",
          SensorId: "0",
          FieldName: "",
          InitValue: "",
          FieldUnit: "",
        };
      } else {
        this.formInitvalue = JSON.parse(JSON.stringify(item));
        this.editIndex = index;
      }
      this.formInitvalueType = type;
      this.dialogFormInitvalueVisible = true;
    },
    onInitvalueSubmit() {
      if (this.formInitvalueType == "add") {
        if (this.formtype == "add") {
          this.initValueData.push(
            JSON.parse(JSON.stringify(this.formInitvalue))
          );
          this.$emit("input", this.initValueData);
        } else {
          addDeviceInitvalue(this.formInitvalue)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("保存成功!");
                this.getListData();
              } else {
                this.$message.error("保存失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error("保存失败!" + errmsg);
            });
        }
      } else {
        if (this.formtype == "add") {
          var valueData = this.initValueData;
          valueData[this.editIndex] = JSON.parse(
            JSON.stringify(this.formInitvalue)
          );
          this.initValueData = [];
          setTimeout(() => {
            this.initValueData = valueData;
            this.$emit("input", valueData);
          }, 10);
        } else {
          editDeviceInitvalue(this.formInitvalue)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("保存成功!");
                this.getListData();
              } else {
                this.$message.error("保存失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error("保存失败!" + errmsg);
            });
        }
      }
      this.dialogFormInitvalueVisible = false;
    },
    onInitvalueRemove(item, index) {
      if (this.formtype == "edit") {
        delDeviceInitvalue(item.Id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success("删除成功!");
              this.initValueData.splice(index, 1);
            } else {
              this.$message.error("删除失败!" + res.msg);
            }
          })
          .catch((errmsg) => {
            this.$message.error(errmsg);
          });
      } else {
        this.initValueData.splice(index, 1);
      }
    },
    getListData() {
      getDeviceInitvalue(this.detailid).then((res) => {
        if (res.code == 0) {
          this.initValueData = res.data;
          this.$emit("input", this.initValueData);
        }
      });
    },
  },
  watch: {
    detailid: function () {
      if (this.detailid > 0) {
        this.getListData();
      }
    },
  },
};
</script>